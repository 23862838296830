import { useState, useContext, useEffect } from 'react';
import { useAuthors } from './useAuthors';
import { StyledTable, TableBody, TableCell, TableHead, TableHeading, TableRow, TextField } from './Style';
import Button from '../../../components/elements/Button';
import { Card } from '../../../components/layouts/Card';
import { Row } from '../../../components/layouts/Row';
import { Col } from '../../../components/layouts/Col';
import { TableActionButton } from '../../../components/layouts/TableUpdated/TableActionButton';
import { RightPanelModal } from '../../../components/layouts/RightPanelModal';
import { Input } from '../../../components/inputs/Input';
import { ProfilePicture } from '../../../components/layouts/ProfilePictureThumbnail';
import { TextEmphasis } from '../../../components/elements/TextEmphasis';
import { usePagination } from '../../../components/hooks/usePagination';
import { Pagination } from '../../../components/layouts/Pagination';
import { H3 } from '../../../components/elements/H3';
import { H4 } from '../CategoryTable/Style';
import { Error } from '../../../components/elements/Error';
import { AuthorsContext } from '../../../app/context/AuthorsContext';
import { AuthContext } from '../../../app/context/AuthContext';

const interfaceTexts = {
  en: {
    pageTitle: 'Authors',
    addButton: 'Add Author',
    createNewAuthor: 'Create new author',
    namePlaceholder: 'Name',
    initialsPlaceholder: 'Initials',
    saveButton: 'Save',
    tableHeadings: ['Name', 'Initials', '', 'Actions'],
    errorNewAuthor: "Add author's name and initials to save",
  },
  fr: {
    pageTitle: 'Auteurs',
    addButton: 'Ajouter un auteur',
    createNewAuthor: 'Créer un nouvel auteur',
    namePlaceholder: 'Nom',
    initialsPlaceholder: 'Initiales',
    saveButton: 'Enregistrer',
    tableHeadings: ['Nom', 'Initiales', '', 'Actions'],
    errorNewAuthor: "Ajoutez le nom et les initiales de l'auteur pour enregistrer",
  },
};

export const AuthorTable = () => {
  // const { authors, store, authorStore, updateAuthorStore, getAllAuthors, createAuthor, updateAuthor, deleteAuthor } = useAuthors()
  const { authors, store, authorStore, updateAuthorStore, getAllAuthors, createAuthor, updateAuthor, deleteAuthor } =
    useContext(AuthorsContext);
  const { user } = useContext(AuthContext);
  const appLocale = user ? user.appLocale : 'fr';

  const [editingId, setEditingId] = useState(null);
  const [name, setName] = useState('');
  const [img, setImg] = useState('');
  const [initials, setInitials] = useState('');
  const [addMode, setAddMode] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isProfilePictureEditing, setIsProfilePictureEditing] = useState(false);

  const [errorNewAuthor, setErrorNewAuthor] = useState('');

  const handleAuthorChange = (id, property, value) => {
    const index = authors.findIndex((author) => author.id === id);
    const updatedAuthor = { ...authors[index], [property]: value };
    const updatedAuthors = [...authors];
    updatedAuthors[index] = updatedAuthor;
    updateAuthorStore({ ...authorStore, authors: updatedAuthors });
  };

  const handleSaveAuthor = async (author) => {
    await updateAuthor(author);
    setEditingId(null);
  };

  const handleCancelEdit = (author) => {
    const updatedAuthors = authors.map((a) => {
      if (a.id === author.id) {
        return { ...a, editMode: false };
      }
      return a;
    });
    // setAuthors(updatedAuthors)
    updateAuthorStore({ ...authorStore, authors: updatedAuthors });
  };

  const handleEditAuthor = (author) => {
    const updatedAuthors = authors.map((a) => {
      if (a.id === author.id) {
        return { ...a, editMode: true };
      }
      return a;
    });
    // setAuthors(updatedAuthors)
    updateAuthorStore({ ...authorStore, authors: updatedAuthors });
  };

  const handleDeleteAuthor = async (author) => {
    await deleteAuthor(author.id);
  };

  const handleCreateAuthor = async (author) => {
    if (!name) {
      return setErrorNewAuthor(interfaceTexts[appLocale]?.errorNewAuthor);
    }

    await createAuthor({ name: name, picture: img, initials: initials });
    setAddMode(false);
    setName('');
    setImg('');
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    // reader.onload = () => {
    //   setEditedData((prevState) => ({
    //     ...prevState,
    //     picture: reader.result,
    //   }))
    reader.onload = () => {
      setImg(reader.result);
    };
    setIsProfilePictureEditing(false);
  };

  useEffect(() => {}, [authors]);

  const { displayedItems, totalPages, currentPage, handlePageChange } = usePagination(authors, 5);

  return (
    <Card>
      <Row align="center" justify="space-between" width="100%" margin="0">
        <H3>{interfaceTexts[appLocale]?.pageTitle}</H3>
        {!addMode && (
          <Button
            marginLeft="auto"
            onClick={() => {
              setAddMode(true);
              setViewModal(true);
            }}
          >
            {interfaceTexts[appLocale]?.addButton}
          </Button>
        )}
        {addMode && (
          <RightPanelModal
            shouldShow={viewModal}
            onRequestClose={() => {
              setViewModal(false);
              setAddMode(false);
            }}
          >
            <Col justify="flex-start">
              <H4>{interfaceTexts[appLocale]?.createNewAuthor}</H4>
              <TextField
                type="text"
                placeholder={interfaceTexts[appLocale]?.namePlaceholder}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                width="6.5rem"
                type="text"
                placeholder={interfaceTexts[appLocale]?.initialsPlaceholder}
                value={initials}
                onChange={(e) => setInitials(e.target.value)}
              />

              {errorNewAuthor && <Error>{errorNewAuthor}</Error>}
              <Button onClick={handleCreateAuthor} marginLeft="auto">
                {interfaceTexts[appLocale]?.saveButton}
                <TableActionButton iconClassName={interfaceTexts[appLocale]?.saveIcon} color="white" />
              </Button>
            </Col>
          </RightPanelModal>
        )}
      </Row>
      <StyledTable>
        <TableHead>
          <tr>
            {interfaceTexts[appLocale]?.tableHeadings.map((heading, index) => (
              <TableHeading key={index}>{heading}</TableHeading>
            ))}
          </tr>
        </TableHead>
        <TableBody>
          {displayedItems.map((author) => (
            <TableRow key={author.id}>
              <TableCell style={{ textAlign: 'left', paddingLeft: '3rem' }}>
                {author.editMode ? (
                  <input
                    type="text"
                    value={author.name}
                    onChange={(e) => handleAuthorChange(author.id, 'name', e.target.value)}
                  />
                ) : (
                  author.name
                )}
              </TableCell>
              <TableCell>
                {author.editMode ? (
                  <input
                    type="text"
                    value={author.initials}
                    onChange={(e) => handleAuthorChange(author.id, 'initials', e.target.value)}
                  />
                ) : (
                  author.initials
                )}
              </TableCell>
              <TableCell>{author.editMode ? author.img : author.img}</TableCell>
              <TableCell>
                {author.editMode ? (
                  <>
                    <TableActionButton
                      iconClassName="fa-solid fa-check-circle"
                      onClick={() => handleSaveAuthor(author)}
                    />
                    <TableActionButton iconClassName="fa-solid fa-xmark" onClick={() => handleCancelEdit(author)} />
                  </>
                ) : (
                  <>
                    <TableActionButton
                      iconClassName="fa-solid fa-pen-to-square"
                      onClick={() => handleEditAuthor(author)}
                    />
                    <TableActionButton iconClassName="fa-solid fa-trash" onClick={() => handleDeleteAuthor(author)} />
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
      <Pagination pageCount={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
    </Card>
  );
};
