import { useEffect } from 'react';
import createHighVelocityContext from '../../../utils/createHighVelocityContext';
import ApiHttpClient from '../../../utils/api/api-http-client';

const { Provider, useStore } = createHighVelocityContext({
  authors: [],
  isCreating: false,
});

export const useAuthors = () => {
  const [authorStore, updateAuthorStore] = useStore((store) => store);
  // const [authors, setAuthors] = useState([])
  const apiClient = new ApiHttpClient();

  const { authors } = authorStore;

  const getAllAuthors = async () => {
    const { data } = await apiClient.get('/authors');
    updateAuthorStore({ authors: data });
    // setAuthors(data)
  };

  const createAuthor = async (newAuthor) => {
    const { data } = await apiClient.post('/authors', newAuthor);
    updateAuthorStore({ authors: [...authors, data] });
    // setAuthors([...authors, data])
  };

  const updateAuthor = async (updatedAuthor) => {
    const { data } = await apiClient.put(`/authors/${updatedAuthor.id}`, updatedAuthor);
    const updatedAuthors = authors.map((author) => (author.id === data.id ? data : author));
    // setAuthors(updatedAuthors)
    updateAuthorStore({ authors: updatedAuthors });
  };

  const deleteAuthor = async (id) => {
    if (window.confirm('Are you sure you want to delete this author?')) {
      const { data } = await apiClient.delete(`/authors/${id}`);
      const filteredAuthors = authors.filter((author) => author.id !== id);
      // setAuthors(filteredAuthors)
      updateAuthorStore({ authors: filteredAuthors });
    }
  };

  useEffect(() => {
    getAllAuthors();
  }, []);

  return { authors, authorStore, updateAuthorStore, getAllAuthors, createAuthor, updateAuthor, deleteAuthor };
};

export { Provider as AuthorsProvider };
